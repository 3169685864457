<script lang="ts" setup>
import { useUserInfo } from '~/composables/app'

const $options = ref<HTMLElement>()
const { t: $t } = useNuxtApp().$i18n
const { userInfo, clearInfo } = useUserInfo()
const open = ref(false)
const isLogout = ref(false)
const count = computed(() => {
  return `${formatUserAmount(userInfo.value?.trx_money)}TRX`
})
const localeOptions = computed(() => [
  { icon: 'i-local:user?mask', path: `/console/buyer/dashboard?lang=${$t('console-lang')}`, name: `${$t('i7bCixcQ0eGz5NzVrm00T')}` },
  { icon: 'i-local:agent-icon?mask', path: `/console/buyer/agent?lang=${$t('console-lang')}`, name: `${$t('jFqii2AJfaE4a8k7iUyUg')}` },
  userInfo.value?.seller?.status === 0 ? undefined : { icon: 'i-local:sell-icon?mask', path: `/console/seller/dashboard?lang=${$t('console-lang')}`, name: `${$t('omLpjx8ZoQ3krwOlxIdG')}` },
  { icon: 'i-local:out-icon?mask', name: `${$t('jz9d6i6ySneWChWaGl')}` },
].filter(i => !!i))
function select(path?: string) {
  if (path)
    window.open(path, '_blank')
  else
    isLogout.value = true
}
const loadLogout = ref(false)
async function logout() {
  if (hasToken()) {
    try {
      loadLogout.value = true
      await apis.authLogout(undefined)
      clearInfo()
      loadLogout.value = false
      aMessage.success($t('zvpQimqzEbDu2UpIa73yP'))
      isLogout.value = false
    }
    catch {
      console.log('注销Token失败')
      loadLogout.value = false
    }
  }
}
</script>

<template>
  <ADropdown v-model:open="open" trigger="hover" :overlayStyle="{ zIndex: 5000 }" class="hidden laptop:block">
    <a
      ref="$options"
      :href="`/console?lang=${$t('console-lang')}`"
      :title="userInfo?.account"
      class="user-wrapper text-[#e2e8f2]"
      data-test-id="fuz2"
      @click="open = true"
    >
      <p v-if="userInfo" class="flex-between">
        <i class="icon i-local:user mr-0.2em text-16px" />
        <span class="account inline-block w-96px truncate">
          {{ userInfo?.account }}
        </span>
      </p>
      <p v-else>{{ $t("vTL9tlsWzbAAgQHYbEYMq") }}</p>
    </a>
    <template #overlay>
      <div v-show="userInfo" class="option-box">
        <div class="count"><i class="i-local:pocket" />{{ count }}</div>
        <ul class="user-options">
          <li v-for="(item, index) in localeOptions" :key="index" class="option" data-test-id="eusu" @click="select(item?.path)">
            <i :class="item?.icon" />{{ item?.name }}
          </li>
        </ul>
      </div>
    </template>
  </ADropdown>
  <AModal
    v-model:open="isLogout"
    :footer="null"
    destroyOnClose
    :closable="true"
    :maskClosable="false"
    wrapClassName="ant-cover__basic-modal"
    centered
    :title="$t('mxo0cnGrkxXuDuAchSx')"
  >
    <div class="mx-auto mt-[32px]">
      <p class="text-18px text-text text-center">{{ $t('vgSxF2KG5obiPgZv4sK0') }}</p>
      <div class="flex justify-center items-center mt4 gap4">
        <AButton class="cancel-btn flex-1" data-test-id="5eql" @click="isLogout = false">{{ $t('hAns2WaHjEPbTgBxlqXn') }}</AButton>
        <AButton class="comfirm-btn flex-1" :loading="loadLogout" data-test-id="assi" @click="logout()">{{ $t('UENBgObobQIqSj65k6uBN') }}</AButton>
      </div>
    </div>
  </AModal>
</template>

<style lang="scss" scoped>
.user-wrapper{
  --uno: 'text-14px text-[#e2e8f2]';

  align-items: center;
  justify-content: center;
  height: 30px;
  padding: 0 20px;
  line-height: 1.9;
  background-image:
    linear-gradient(#161d36, #161d36),
    linear-gradient(to left, #00c1a5, #2a47ab);
  background-clip: padding-box, border-box;
  background-origin: border-box;
  background-size: cover;
  border: 1.5px solid transparent;
  border-radius: 16px;
}

.option-box{
  width: 160px;
  padding: 0;
  overflow: hidden;
  font-size: 14px;
  background-color: #202c52;
  border-radius: 10px;

  .count{
    padding: 10px 6px;
    margin: 10px;
    color: #e2e8f2;
    background: linear-gradient(180deg, #2e3f74, #333C67);
    border-radius: 10px;
  }

  i{
    --uno: 'mb-4px mr-4px text-17px';
  }
}

.user-options {
  .option {
    padding: 0.8em 1.2em;
    font-size: 16px;
    color: rgb(128 152 191);
    cursor: pointer;

    &:hover {
      color: #e2e8f2;
      background-color: #2e3f73;
    }
  }
}
</style>
